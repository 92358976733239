import React from 'react';
import classNames from 'classnames';

import Transaction from '../Transaction/Transaction';

import styles from './TransactionsList.module.scss';

const TransactionsList = ({ transactions }) => {
  return (
    <div className={classNames(styles.table)}>
      {transactions.map((transaction) => (
        <Transaction key={transaction.created_at} transaction={transaction} />
      ))}
    </div>
  );
};

export default TransactionsList;
