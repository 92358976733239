import { getRequiredFieldsValues } from '@utilities/helpers';

function loginFormValidation() {
  const loginForm = document.getElementById('login-form');
  if (!loginForm) return;

  const submitButton = loginForm.querySelector('[type="submit"]');
  const requiredFields = loginForm.querySelectorAll('[required]');
  let isFormFilled = false;

  const changeButtonStateOnFormFill = () => {
    isFormFilled = getRequiredFieldsValues(requiredFields);

    if (isFormFilled) {
      submitButton.classList.remove('button--disabled');
    } else {
      submitButton.classList.add('button--disabled');
    }
  };

  changeButtonStateOnFormFill();

  Array.from(requiredFields).forEach((field) => {
    field.addEventListener('input', () => {
      changeButtonStateOnFormFill();
    });
  });
}

export default loginFormValidation;
