import React, { useState, useMemo, useCallback } from 'react';
import classNames from 'classnames';

import { useTransactions, useTotalTransactionsNumber } from '../../hooks/useTransactions';
import CampaignStatisticsWrapper from '../CampaignStatisticsWrapper/CampaignStatisticsWrapper';
import Loader from '../Loader/Loader';
import StatsInfo from '../StatsInfo/StatsInfo';
import TransactionsFilter from '../TransactionsFilter/TransactionsFilter';
import TransactionsList from '../TransactionsList/TransactionsList';
import EmptyTransactions from '../EmptyTransactions/EmptyTransactions';
import Pagination from '../Pagination/Pagination';
import Heading from '../Heading/Heading';

import styles from './CampaignStatistics.module.scss';

const CampaignStatistics = () => {
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [limit, setLimit] = useState(10);
  const campaignSlug = document.getElementById('single-campaign').dataset.slug;

  const { data, isLoading } = useTotalTransactionsNumber(campaignSlug);

  const total = data?.data.total;
  const tabs = data?.data.tabs;
  const orderTypes = useMemo(() => {
    if (tabs) {
      return Object.keys(tabs).filter((key) => tabs[key] !== 0);
    }
    return [];
  }, [tabs]);

  const calculateOffsetCallback = useCallback(
    (transactionsCount) => {
      return (page - 1) * limit > transactionsCount ? transactionsCount - 1 : (page - 1) * limit;
    },
    [page, limit],
  );

  const {
    data: queryData,
    isSuccess,
    isError,
    error,
    isPlaceholderData,
  } = useTransactions(campaignSlug, page, filter, limit, total, calculateOffsetCallback, tabs);

  if (isLoading)
    return (
      <CampaignStatisticsWrapper>
        <Loader />
      </CampaignStatisticsWrapper>
    );

  if (isError)
    return (
      <CampaignStatisticsWrapper>
        <p>ERROR: {error.message}</p>
      </CampaignStatisticsWrapper>
    );

  return (
    <CampaignStatisticsWrapper>
      <StatsInfo
        max={data.data.amount__max}
        avg={Math.round(data.data.amount__avg)}
        count={data.data.total}
        mono={data.data.mono}
      />

      <div className={classNames(styles.wrapper)}>
        <Heading>Таблиця донатів</Heading>

        {orderTypes.length > 0 && (
          <div className={classNames(styles.filter)}>
            <TransactionsFilter
              tabs={orderTypes}
              currentFilter={filter}
              setFilter={setFilter}
              setPage={setPage}
            />
          </div>
        )}

        {isSuccess ? (
          <div className={classNames(styles.transactions)}>
            <TransactionsList transactions={queryData.data.transactions} />

            <Pagination
              pagination={queryData.data.pagination}
              currentPage={queryData.data.pagination.current_page}
              lastPage={queryData.data.pagination.last_page}
              setPage={setPage}
              setLimit={setLimit}
              isPlaceholderData={isPlaceholderData}
            />
          </div>
        ) : (
          <EmptyTransactions />
        )}
      </div>
    </CampaignStatisticsWrapper>
  );
};

export default CampaignStatistics;
