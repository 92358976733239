import { getRequiredFieldsValues } from '@utilities/helpers';

import { matchPasswordValues } from './passwordField';

const signupFormValidation = () => {
  const signupForm = document.getElementById('signup-form');

  if (!signupForm) return;

  const passwordInput = document.getElementById('user-password');
  const passwordInputConfirm = document.getElementById('user-password-confirm');
  const submitButton = signupForm.querySelector('[type="submit"]');
  const requredFields = signupForm.querySelectorAll('[required]');

  let isFofmFilled = false;

  /**
   * Change button submission status depends on field fulfillment
   */
  const changeButtonStateOnFormFill = () => {
    isFofmFilled = getRequiredFieldsValues(requredFields);

    if (isFofmFilled && passwordInput.value === passwordInputConfirm.value) {
      submitButton.classList.remove('button--disabled');
    } else {
      submitButton.classList.add('button--disabled');
    }
  };

  changeButtonStateOnFormFill();

  /**
   * Checking password fields value match
   */
  passwordInputConfirm.addEventListener('input', () => {
    matchPasswordValues(passwordInputConfirm, passwordInput);
  });

  /**
   * Checking that all fields are filled in
   */
  Array.from(requredFields).forEach((field) => {
    if (field.type === 'checkbox') {
      field.addEventListener('change', () => {
        changeButtonStateOnFormFill();
      });
    } else {
      field.addEventListener('input', () => {
        changeButtonStateOnFormFill();
      });
    }
  });
};

export default signupFormValidation;
